body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
font-family: "BlancoBold";
src: local("BlancoBold"),
 url("./assets/fonts/Blanco/Blanco-Bold.ttf") format("truetype");
}
@font-face {
font-family: "BlacoExtra";
src: local("BlacoExtra"),
 url("./assets/fonts/Blanco/Blanco-ExtraBold.ttf") format("truetype");
}
@font-face {
font-family: "CiudadelaMedium";
src: local("CiudadelaMedium"),
 url("./assets/fonts/Ciudadela/Ciudadela-Pro-Regular.otf") format("truetype");
}
@font-face {
font-family: "CiudadelaBold";
src: local("CiudadelaBold"),
 url("./assets/fonts/Ciudadela/Ciudadela-Pro-Medium.otf") format("truetype");
}

@font-face {
font-family: "CiudadelaBoldItalic";
src: local("CiudadelaBoldItalic"),
 url("./assets/fonts/Ciudadela/Ciudadela-Pro-Bold-Italic.otf") format("truetype");
}
@font-face {
font-family: "CiudadelaMediumItalic";
src: local("CiudadelaMediumItalic"),
 url("./assets/fonts/Ciudadela/Ciudadela-Pro-Medium-Italic.otf") format("truetype");
}
.mapboxgl-popup-content {}
.mapboxgl-popup-tip{   }

.mapboxgl-popup-content {
  padding: 0 !important;
  background-color: #006aab;
}
.mapboxgl-popup-tip {
  margin:0; padding: 0;
  border-left-color: #006aab;
  border-right-color: #006aab;
  border-bottom-color: #006aab;
  border-left-color: #006aab;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip{
  border-bottom-color:#006aab !important;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip{
  border-top-color:#006aab !important;
}
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip{
  border-bottom-color:#006aab !important;
}
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip{
  border-bottom-color:#006aab !important;
}
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip{
  border-top-color:#006aab !important;
}
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip{
    border-top-color:#006aab !important;
}
